import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { d3_plot, show_baseline_acc_dec } from './d3plot.js';


export default function Plot(props) {
  useEffect(() => {
    d3_plot(props.id, props.data);
    if (props.show) show_baseline_acc_dec();
  });

  return (
    <div>
      <Paper elevation={3} style={{ padding: 8 }} sx={{ my: 2 }}>
        <div id={props.id}></div>
      </Paper>
    </div>
  )
}
