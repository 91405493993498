
import * as d3 from 'd3';

export function d3_plot(div_id, observation) {

  d3.select('#' + div_id).selectAll('.plot').remove();

  // Select the observation to display
  var fhr = observation.fhr;
  var baseline = observation.features.baseline;
  var uc = observation.uc;
  var x = Array.from(Array(fhr.length).keys()).map(function (d) {return d / (4 * 60)});
  var to_display = fhr.map(function(_, i) {return {x: x[i], fhr: fhr[i], uc: uc[i], baseline: baseline[i]}});

  // Add a div to contain the whole visualization
  var container = d3.select('#' + div_id)
              .append('div')
              .attr('class', 'plot');

  // Set width, height and padding of the svgs displaying FHR and UC
  var width = Math.floor(fhr.length / (4 * 60) * 30);
  var height = 200;
  var padding = 25;

  // Both svgs
  var svg_fhr = container.append("svg")
    .attr('width', width)
    .attr('height', height)
    .attr('id', 'fhr');
  var svg_uc = container.append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('id', 'uc');

  // Build scales
  var x_scl = d3.scaleLinear().domain([0, to_display.length / (4 * 60)]).range([padding, width - padding]);
  var fhr_scl = d3.scaleLinear().domain([60, 200]).range([height - padding, padding]);
  var uc_scl = d3.scaleLinear().domain([0, 100]).range([height - padding, padding]);

  // Build axes
  var x_nb_ticks = Math.floor((x_scl.domain()[1] - x_scl.domain()[0]) / 5);
  var fhr_nb_ticks = Math.floor((fhr_scl.domain()[1] - fhr_scl.domain()[0]) / 20);
  var uc_nb_ticks = Math.floor((uc_scl.domain()[1] - uc_scl.domain()[0]) / 20);
  var x_axis = d3.axisBottom().scale(x_scl).ticks(x_nb_ticks).tickFormat(function (d) { return 5 * x_nb_ticks - d });
  var fhr_axis = d3.axisLeft().scale(fhr_scl).ticks(fhr_nb_ticks);
  var uc_axis = d3.axisLeft().scale(uc_scl).ticks(uc_nb_ticks);

  // Draw X-axes
  svg_fhr.append("g")
    .attr("transform", "translate(" + 0 + "," + (height - padding) + ")")
    .attr("class", "x-axis")
    .attr("text-anchor", "middle")
    .call(x_axis);
  svg_uc.append("g")
    .attr("transform", "translate(" + 0 + "," + (height - padding) + ")")
    .attr("class", "x-axis")
    .attr("text-anchor", "middle")
    .call(x_axis);

  // Draw Y-axes
  var nb_y_axes = Math.floor(x_scl.domain()[1] / 5);
  for (var i = 0; i < nb_y_axes; i++) {
    svg_fhr.append("g")
      .attr("transform", "translate(" + x_scl(i * 5) + "," + 0 + ")")
      .attr("class", "y-axis")
      .attr("text-anchor", "middle")
      .call(fhr_axis)
        .selectAll("text")
        .attr("transform", "translate(-10, 0)");
    svg_uc.append("g")
      .attr("transform", "translate(" + x_scl(i * 5) + "," + 0 + ")")
      .attr("class", "y-axis")
      .attr("text-anchor", "middle")
      .call(uc_axis)
        .selectAll("text")
        .attr("transform", "translate(-10, 0)");
  }

  // Add grids
  var x_nb_grids = Math.floor((x_scl.domain()[1] - x_scl.domain()[0]) / 1);
  var fhr_nb_grids = Math.floor((fhr_scl.domain()[1] - fhr_scl.domain()[0]) / 20);
  var uc_nb_grids = Math.floor((uc_scl.domain()[1] - uc_scl.domain()[0]) / 20);

  var x_grid = d3.axisBottom().scale(x_scl).tickSize(- height + padding).tickFormat('').ticks(x_nb_grids);
  var fhr_grid = d3.axisLeft().scale(fhr_scl).tickSize(- width + padding).tickFormat('').ticks(fhr_nb_grids);
  var uc_grid = d3.axisLeft().scale(uc_scl).tickSize(- width + padding).tickFormat('').ticks(uc_nb_grids);
  svg_fhr.append('g')
    .attr("transform", "translate(" + 0 + "," + (height - padding) + ")")
    .attr("class", "grid")
    .call(x_grid);
  svg_uc.append('g')
    .attr("transform", "translate(" + 0 + "," + (height - padding) + ")")
    .attr("class", "grid")
    .call(x_grid);
  svg_fhr.append('g')
    .attr("transform", "translate(" + padding + "," + 0 + ")")
    .attr("class", "grid")
    .call(fhr_grid);
  svg_uc.append('g')
    .attr("transform", "translate(" + padding + "," + 0 + ")")
    .attr("class", "grid")
    .call(uc_grid);

  // Draw signals
  svg_fhr.append("path")
    .attr("d", d3.line()
      .defined(function(d) { return d.fhr!==null })
      .x(function(d) { return x_scl(d.x); })
      .y(function(d) { return fhr_scl(d.fhr); })
      (to_display)
    )
    .attr("class", "signal_line")
  svg_uc.append("path")
    .attr("d", d3.line()
      .defined(function(d) { return d.uc!==null })
      .x(function(d) { return x_scl(d.x); })
      .y(function(d) { return uc_scl(d.uc); })
      (to_display)
    )
    .attr("class", "signal_line")

  // Draw baseline
  svg_fhr.append("path")
    .attr("d", d3.line()
      .defined(function(d) { return d.baseline!==null })
      .x(function(d) { return x_scl(d.x); })
      .y(function(d) { return fhr_scl(d.baseline); })
      (to_display)
  )
  .attr("class", "baseline_line")
  .attr("visibility", "hidden")

  // Draw accelerations and decelerations
  var area = d3.area()
               .x(function(d) {return x_scl(d.x); })
               .y0(function(d) { return fhr_scl(d.baseline); })
               .y1(function(d) { return fhr_scl(d.fhr); });
  var acc, start, end;
  for (acc of observation.features.accelerations) {
    start = acc[0] / (4 * 60);
    end = acc[1] / (4 * 60);
    svg_fhr.append("path")
      .datum(to_display.filter(t => (t.x>=start) && (t.x<=end)))
      .attr("class", "area_acc")
      .attr("d", area)
      .attr("visibility", "hidden");
  }
  for (acc of observation.features.decelerations) {
    start = acc[0] / (4 * 60);
    end = acc[1] / (4 * 60);
    svg_fhr.append("path")
      .datum(to_display.filter(t => (t.x>=start) && (t.x<=end)))
      .attr("class", "area_dec")
      .attr("d", area)
      .attr("visibility", "hidden");
  }
}

export function show_baseline_acc_dec(div_id, observation) {
  d3.selectAll('.baseline_line').attr("visibility", "visible");
  d3.selectAll('.area_acc').attr("visibility", "visible");
  d3.selectAll('.area_dec').attr("visibility", "visible");
}
