import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


export default function TopBar() {

  return (
    <AppBar position="sticky" style={{ backgroundColor: 'white', color: '#08244d' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/"
              style={{ textDecoration: 'none', color: 'inherit' }}>
            <div style={{display:"flex"}}>
              <img src="./logo-removebg.png"
                  alt="Fetal Heart Rate"
                  style={{ width: '50px', paddingRight: '1px' }}>
              </img>
              <Typography variant="h4">
                Genos Care
              </Typography>
            </div>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
