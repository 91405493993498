import React from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TopBar from "../components/TopBar.js";
import ClinicalData from '../components/ClinicalData.js';
import Plot from '../components/Plot.js';


function PageHeader() {
  const headerText = "Using maternofetal data and cardiotocography signals (fetal heart rate and uterine contractions) before labour, try to determine the fetal outcome: normal (pH > 7.15) or severe hypoxia (pH < 7.15).";

  return (
    <div>
      <Typography variant="h4" align="center" color="#08244d" sx={{ my: 2 }}>
        Quizz
      </Typography>

      <Typography variant="body1" align='center' color='#08244d'  sx={{ my: 2 }}>
        {headerText}<br />
        This quizz has been built using 100 cases from the open CTU-UHB database.
        50 of those cases correspond to a normal outcome while the 50 remaining correspond to a severe hypoxia.
      </Typography>
    </div>
  );
}


function Evaluate(props) {
  return (
    <div>
      <Grid container spacing={1} align="center" justify="center" alignItems="center">
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="success" size="small" onClick={() => props.click('normal')}>Normal outcome</Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="error" size="small" onClick={() => props.click('acidose')}>Severe hypoxia</Button>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  )
}

function Next(props) {
  return (
    <div>
      <Typography variant="h6" align="center" color="#08244d" sx={{ my: 1 }}>
        {props.success ? "Congrats !" : "No !"}
      </Typography>

      <Grid container spacing={1} align="center" justify="center" alignItems="center">
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={props.changeState}
                  style={{ backgroundColor: "#08244d" }}>
            Next
          </Button>
        </Grid>
        <Grid item xs={5}></Grid>
      </Grid>
    </div>
  )
}


export default class Quizz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      recordIndex: null,
      recordData: null,
      state: 'evaluation',
      show: false
    };

    this.fetchRecords = this.fetchRecords.bind(this);
    this.fetchRecord = this.fetchRecord.bind(this);
    this.click = this.click.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  componentDidMount() {
    this.fetchRecords();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recordIndex !== prevState.recordIndex) {
      this.fetchRecord(this.state.records[this.state.recordIndex]);
    }
  }

  fetchRecords() {
    fetch("https://api.genos.care:80/records")
        .then(res => res.json())
        .then(result => {
          // Draw a random record
          let recordIndex = Math.floor(result.length * Math.random());

          // setState
          this.setState({
            records: result.map(r => r.id),
            recordIndex: recordIndex,
            recordData: this.state.recordData,
            state: this.state.state,
            show: this.state.show
          })
        })
  }

  fetchRecord(id) {
    fetch("https://api.genos.care:80/record/id/" + id)
        .then(res => res.json())
        .then(result => {
          this.setState({
            records: this.state.records,
            recordIndex: this.state.recordIndex,
            recordData: result,
            state: this.state.state,
            show: this.state.show
          })
        })
  }

  click(clicked) {
    var ph = JSON.parse(this.state.recordData.metadata).outcome.ph;
    var success = ((ph>=7.15) && (clicked==='normal')) ||
                  ((ph<7.15) && (clicked==='acidose'));
    this.changeState(success);
  }

  changeState(success) {
    if (this.state.state === "evaluation") {
      this.setState({
        records: this.state.records,
        recordIndex: this.state.recordIndex,
        recordData: this.state.recordData,
        state: "next",
        show: true,
        success: success
      })
    } else {
      this.setState({
        records: this.state.records,
        recordIndex: Math.floor(this.state.records.length * Math.random()),
        recordData: this.state.recordData,
        state: "evaluation",
        show: false,
        success: success
      })
    }
  }

  render() {
    let clinicalData, plot, button;
    if (this.state.recordData==null) {
      clinicalData = <div></div>;
      plot = <div></div>;
      button = <div></div>;
    } else {
      clinicalData = <ClinicalData metadata={JSON.parse(this.state.recordData.metadata)}
        showOutcome={this.state.show}
        colorOutcome={this.state.state==='evaluation' ? 'none' : 'colored'}/>;
      let plotData = {fhr: JSON.parse(this.state.recordData.fhr),
                      uc: JSON.parse(this.state.recordData.uc),
                      features: JSON.parse(this.state.recordData.features)
                    }
      plot = <Plot id='visualization' data={plotData} show={this.state.show}/>;
      button = (this.state.state === 'evaluation')
          ? <Evaluate click={this.click}/>
        : <Next changeState={this.changeState} success={this.state.success}/>;
    }

    return (
      <div>
        <TopBar />

        <Box sx={{ mx: 2, my: 2 }}>
          <PageHeader />

          {clinicalData}
          {plot}

          <Box sx={{ mx: 2, my: 2 }}>
            {button}
          </Box>

        </Box>
      </div>
    )
  }
}
