import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './views/Home.js';
import Quizz from './views/Quizz.js';
import Exploration from './views/Exploration.js';
import Prediction from './views/Prediction.js';

import './index.css';


// Build the theme applied to the whole app
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto'
  },
  palette: {
      mode: 'light',
  }
});


function App() {
  useEffect(() => {
    document.title = "Genos Care : Analyse automatique de la cardiotocographie";
  })

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/quizz" element={<Quizz />}/>
          <Route path="/exploration" element={<Exploration />}/>
          {/*<Route path="/prediction" element={<Prediction />}/>*/}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
