import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Typography } from '@mui/material';
import TopBar from "../components/TopBar.js";
import ClinicalData from "../components/ClinicalData.js";
import Plot from '../components/Plot.js';
import { min, median, max, floor } from 'mathjs';



function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [plotData, setPlotData] = useState(null);

  const click = () => {
    // Fetch data if needed
    if (!open) {
      fetch("https://api.genos.care:80/record/id/" + row.id)
          .then(res => res.json())
          .then(result => {
            setPlotData({
              fhr: JSON.parse(result.fhr),
              uc: JSON.parse(result.uc),
              features: JSON.parse(result.features)
            })
          })
    }
    setOpen(!open);
  }

  return (
    <React.Fragment>
      <TableRow onClick={click} hover>
        <TableCell align="center">{floor(row.variables.baseline_min)}</TableCell>
        <TableCell align="center">{floor(row.variables.baseline_median)}</TableCell>
        <StyledTableCell align="center">{floor(row.variables.baseline_max)}</StyledTableCell>
        <TableCell align="center">{row.variables.nb_decelerations_m2}</TableCell>
        <StyledTableCell align="center">{row.variables.nb_decelerations_2}</StyledTableCell>
        <TableCell align="center">{row.metadata.outcome.ph}</TableCell>
        <TableCell align="center">{row.metadata.outcome.apgar_1}</TableCell>
        <TableCell align="center">{row.metadata.outcome.apgar_5}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <Box sx={{ my: 2 }}>
              <ClinicalData metadata={row.metadata}
                      showOutcome={true}
                      colorOutcome={'none'}/>
            </Box>
            <Box sx={{ my: 2 }}>
              {plotData!==null &&
                <Plot id={"explo_" + row.id} data={plotData} show={true}/>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const StyledTableHead = styled(TableHead)({
  borderBottom: '2px solid #08244d',
  borderTop: '2px solid #08244d',
});

const StyledTableCell = styled(TableCell)({
  borderRight: '1px solid #08244d',
});

export default function Exploration(props) {

  const [records, setRecords] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [minBaselineSorter, setMinBaselineSorter] = useState(false);
  const [medianBaselineSorter, setMedianBaselineSorter] = useState(false);
  const [maxBaselineSorter, setMaxBaselineSorter] = useState(false);
  const [nbDecSorter, setNbDecSorter] = useState(false);
  const [nbDec2Sorter, setNbDec2Sorter] = useState(false);
  const [phSorter, setPhSorter] = useState(false);
  const [apgar1Sorter, setApgar1Sorter] = useState(false);
  const [apgar5Sorter, setApgar5Sorter] = useState(false);

  useEffect(() => {
    fetch("https://api.genos.care:80/records_exploration")
        .then(res => res.json())
        .then(result => {
          setRecords(
            result.map(r => (
              {
                id: r.id,
                metadata: JSON.parse(r.metadata),
                variables: JSON.parse(r.variables)
              }
            ))
          )
        })
  }, []);

  const rowClick = r => setSelectedRecord(r.id);
  const unsetAllSorter = () => {
    setMinBaselineSorter(false);
    setMedianBaselineSorter(false);
    setMaxBaselineSorter(false);
    setNbDecSorter(false);
    setNbDec2Sorter(false);
    setPhSorter(false);
    setApgar1Sorter(false);
    setApgar5Sorter(false);
  }
  const sortMinBaseline = () => {
    unsetAllSorter();
    setMinBaselineSorter(!minBaselineSorter);
  }
  const sortMedianBaseline = () => {
    unsetAllSorter();
    setMedianBaselineSorter(!medianBaselineSorter);
  }
  const sortMaxBaseline = () => {
    unsetAllSorter();
    setMaxBaselineSorter(!maxBaselineSorter);
  }
  const sortNbDec = () => {
    unsetAllSorter();
    setNbDecSorter(!nbDecSorter);
  }
  const sortNbDec2 = () => {
    unsetAllSorter();
    setNbDec2Sorter(!nbDec2Sorter);
  }
  const sortPh = () => {
    unsetAllSorter();
    setPhSorter(!phSorter);
  }
  const sortApgar1 = () => {
    unsetAllSorter();
    setApgar1Sorter(!apgar1Sorter);
  }
  const sortApgar5 = () => {
    unsetAllSorter();
    setApgar5Sorter(!apgar5Sorter);
  }

  return (
    <div>
      <TopBar />

      <Box sx={{ mx: 2, my: 4 }}>
        <Typography variant="h5" align="center" color="#08244d" sx={{ my: 2 }}>
          Browse 100 cases from the open CTU-UHB database
        </Typography>
        <Typography variant="body1" align="center" color="#08244d" sx={{ my: 2 }}>
          Among the 100 cases displayed below, 50 correspond to a normal fetal outcome (pH {'>'} 7.15)
          and the remaining 50 correspond to a severe hypoxia.
          Click on any case to show the available clinical maternofetal data as well as the cardiotocography signals.
          Cases can be sorted by any column by clicking on the corresponding column.
        </Typography>

        <Box sx={{ my: 2 }}>
          <TableContainer>
            <Table style={{ tableLayout: 'fixed' }}>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={3}>
                    <Typography variant="overline" color="#08244d">
                      Baseline
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={2}>
                    <Typography variant="overline" color="#08244d">
                      Decelerations
                    </Typography>
                  </StyledTableCell>
                  <TableCell align="center" colSpan={3}>
                    <Typography variant="overline" color="#08244d">
                      Issue foetale
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                        active={minBaselineSorter}
                        direction={'asc'}
                        onClick={sortMinBaseline}>
                      Min
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                        active={medianBaselineSorter}
                        direction={'asc'}
                        onClick={sortMedianBaseline}>
                      Median
                    </TableSortLabel>
                  </TableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                        active={maxBaselineSorter}
                        direction={'asc'}
                        onClick={sortMaxBaseline}>
                      Max
                    </TableSortLabel>
                  </StyledTableCell>
                  <TableCell align="center">
                    <TableSortLabel
                        active={nbDecSorter}
                        direction={'asc'}
                        onClick={sortNbDec}>
                      Nb. ({'<'} 2mn)
                    </TableSortLabel>
                  </TableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                        active={nbDec2Sorter}
                        direction={'asc'}
                        onClick={sortNbDec2}>
                      Nb. (> 2mn)
                    </TableSortLabel>
                  </StyledTableCell>
                  <TableCell align="center">
                    <TableSortLabel
                        active={phSorter}
                        direction={'asc'}
                        onClick={sortPh}>
                      pH
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                        active={apgar1Sorter}
                        direction={'asc'}
                        onClick={sortApgar1}>
                      Apgar-1
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                        active={apgar5Sorter}
                        direction={'asc'}
                        onClick={sortApgar5}>
                      Apgar-5
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {(records!==null) &&
                  records
                      .sort((a, b) => {
                        if (minBaselineSorter) { return a.variables.baseline_min - b.variables.baseline_min; }
                        if (medianBaselineSorter) { return a.variables.baseline_median - b.variables.baseline_median; }
                        if (maxBaselineSorter) { return a.variables.baseline_max - b.variables.baseline_max; }
                        if (nbDecSorter) { return a.variables.nb_decelerations_m2 - b.variables.nb_decelerations_m2; }
                        if (nbDec2Sorter) { return a.variables.nb_decelerations_2 - b.variables.nb_decelerations_2; }
                        if (phSorter) { return a.metadata.outcome.ph - b.metadata.outcome.ph; }
                        if (apgar1Sorter) { return a.metadata.outcome.apgar_1 - b.metadata.outcome.apgar_1; }
                        if (apgar5Sorter) { return a.metadata.outcome.apgar_5 - b.metadata.outcome.apgar_5; }
                        return (a.id - b.id);
                      })
                      .map((record) => (
                    <Row key={record.id} row={record} />
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

    </div>
  )
}
