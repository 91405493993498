import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';


export default function ClinicalData(props) {
  const maternal = props.metadata.maternal;
  const delivery = props.metadata.delivery;
  const fetal = props.metadata.fetal;
  const outcome = props.metadata.outcome;

  const color = outcome.ph>=7.15 ? 'rgba(86, 190, 93, 0.5)' : 'rgba(225, 80, 41, 0.5)';
  const backgroundColor = props.colorOutcome==='none' ? 'rgba(0, 0, 0, 0)' : color;

  const displayOutcome = <div>
                            {delivery.delivery_type==1 ? 'Vaginal delivery' : 'Caesarean section'}
                            <br />
                            pH : {outcome.ph}
                            <br />
                            Apgar-1 : {outcome.apgar_1}
                            <br />
                            Apgar-5 : {outcome.apgar_5}
                        </div>;
  const displayOutcomeUnknown = <div><br />?<br /><br /></div>;

  return (
    <div>

      <Paper elevation={3} style={{ padding: 8, backgroundColor: "#08244d", color: 'white' }}>

        <Grid container spacing={0} align="center" alignItems="center">
          <Grid item xs={4}>
            <Typography variant='body1' align="center">
              Maternal data
            </Typography>
            <br />
            <Typography variant='subtitle2' align="center">
              Age : {maternal.age}
              <br />
              Parity : {maternal.parity}
              <br />
              Gravidity : {maternal.gravidity}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='body1' align="center">
              Fetal data
            </Typography>
            <br />
            <Typography variant='subtitle2' align='center'>
              Sex : {fetal.sex==2 ? "F" : "M"}
              <br />
              Term : {fetal.term} semaines
              <br />
              Weight : {fetal.weight} g
            </Typography>
          </Grid>

          <Grid item xs={4} style={{ backgroundColor: backgroundColor }}>
            <Typography variant='body1' align="center">
              Fetal outcome
            </Typography>
            <br />
            <Typography variant='subtitle2' align="center">
              {props.showOutcome ? displayOutcome : displayOutcomeUnknown}
            </Typography>
          </Grid>
        </Grid>

      </Paper>

    </div>
  )
}
