import { Link } from "react-router-dom";
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TopBar from "../components/TopBar.js";


export default function Home() {
  return (
    <div>

      <TopBar />

      <div style={{ backgroundColor: '#08244d', color: 'white' }}>
        <Box sx={{ px: 4, py: 6 }}>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h4" align='center'>
              Computerized Analysis of Cardiotocography
            </Typography>
          </Box>
          <Typography variant="h6" align="center">
            Cardiotocography consists in monitoring the fetal heart rate and
            uterine contractions during labour.
            It is one of the main tools used to monitor fetal wellbeing during labour.
          </Typography>
          <Typography variant="h6" align="center">
            We are building tools to help practitioners (obstetricians and midwives)
            to interpret those signals, helping them to define the best care during labour.
          </Typography>
        </Box>
      </div>

      <Box sx={{ px: 6, py: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Link to="/quizz" style={{textDecoration: 'none', color: 'inherit'}}>
              <Paper elevation={3} style={{ padding: 8, backgroundColor: '#08244d', color: 'white' }}>
                <Typography variant='h6' align="center">
                  Quizz
                </Typography>
                <Typography variant='body1' align="center">
                  Test your cardiotocography interpretation skills
                </Typography>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/exploration" style={{textDecoration: 'none', color: 'inherit'}}>
              <Paper elevation={3} style={{ padding: 8, backgroundColor: '#08244d', color: 'white' }}>
                <Typography variant='h6' align="center">
                  Clinical cases
                </Typography>
                <Typography variant='body1' align="center">
                  Browse real clinical cases
                </Typography>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Box>

      <div style={{ backgroundColor: '#08244d', color: 'white' }}>
        <Box sx={{ px: 4, py: 6, pb: 30 }}>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h4" align='center'>
              Contact us
            </Typography>
          </Box>

          <Typography variant="h6" align="center">
            Feel free to reach us for any inquiry.
            <br /><br />
            <a href='mailto:contact@genos.care' style={{textDecoration: 'none', color: 'inherit'}}>contact@genos.care</a>
          </Typography>
        </Box>
      </div>

      {/*<div style={{ backgroundColor: '#08244d', color: 'white' }}>
        <Box sx={{ px: 4, py: 6 }}>
          <Box sx={{ pb: 2 }}>
            <Typography variant="h4" align='center'>
              Equipe
            </Typography>
          </Box>

          <Grid container spacing={2} align='center' alignItems='center'>
            <Grid item xs={12} md={4}>
              <Box sx={{ pb: 2 }}>
                <img src="./imane.jpg"
                    alt="Imane"
                    style={{ width: '50%' }}>
                </img>
              </Box>
              <Typography variant="body2">
                Dr. Imane Ben M'Barek
                <br />
                Cheffe de projet
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ pb: 2 }}>
                <img src="./greg.jpg"
                    alt="Grégoire"
                    style={{ width: '50%' }}>
                </img>
              </Box>
              <Typography variant="body2">
                Grégoire Jauvion
                <br />
                Data Scientist
                <br />
                gregoire.jauvion@genos.care
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ pb: 2 }}>
                <img src="./pf.jpg"
                    alt="PF"
                    style={{ width: '50%' }}>
                </img>
              </Box>
              <Typography variant="body2">
                Pr. Pierre-François Ceccaldi
                <br />
                Comité scientifique
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>*/}

    </div>
  )
}
